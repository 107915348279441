import {
    getAnalyticsTag,
    getFlightsTag,
    loadAnalytics,
    priceTransform,
    sendGoogleConversionEvent,
    sendGoogleErrorEvent,
    sendGooglePriceAccuracyEvent,
} from './google_analytics';

window.addEventListener("load", () => {
    const flightsTag = getFlightsTag()
    const analyticsTag = getAnalyticsTag()

    if (flightsTag || analyticsTag) loadAnalytics();
    if (!flightsTag) return false;

    const currency = document.getElementsByTagName('body')[0].getAttribute('currency');

    const step1 = document.getElementById('step_1');
    if (step1) {
        const priceElements = document.getElementsByClassName('price');
        if (priceElements.length === 0) return sendGoogleErrorEvent('OTHER', 'Price element was not found')

        const price = priceTransform(priceElements[0].innerText, currency)

        if(price.length===0) {
            return sendGoogleErrorEvent(); // no data found -> send price accuracy error
        } else {
            return sendGooglePriceAccuracyEvent(price, currency)
        }
    }

    // step2 page -> price accuracy event
    const step2 = document.getElementById('step-2-data');
    if (step2) {
        const price = priceTransform(document.getElementById('sum_span').innerText, currency);

        if(price.length===0) {
            return sendGoogleErrorEvent(); // no data found -> send price accuracy error
        } else {
            return sendGooglePriceAccuracyEvent(price, currency)
        }
    }

    // booking page -> conversion event
    const booking = document.getElementById('booking_page');
    if (booking) {
        const adultCount = parseInt(booking.getAttribute('data-adult-number'));
        const childCount = parseInt(booking.getAttribute('data-child-number'));
        const infantCount = parseInt(booking.getAttribute('data-infant-number'));
        const items = JSON.parse(document.getElementById('google-flights-data').textContent);
        const routeType = booking.getAttribute('data-route-type');
        const transactionTotal = booking.getAttribute('data-transaction-total');


        return sendGoogleConversionEvent(
            routeType,
            transactionTotal,
            currency,
            {
                'total': adultCount+childCount+infantCount,
                'adult': adultCount,
                'child': childCount,
                'infant_in_lap': infantCount
            },
            items
        );
    }
});
