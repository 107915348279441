/* global $ */

import {
    buildConversionData,
    buildPreConversionData,
    isSkyscanner,
    sendConversionEvent,
    sendPreConversionEvent
} from "./skyscanner";


$(function () {
    if(!isSkyscanner()) return false;

    const currency = document.getElementsByTagName('body')[0].getAttribute('currency');

    // step2 page -> pre conversion event
    const step2 = document.getElementById("step-2-data");
    if (step2) {
        const payload = buildPreConversionData(
            currency,
            step2.getAttribute("data-fare-basis-codes"),
            step2.getAttribute("data-marketing-carriers"),
            document.getElementById("sum_span").innerText,
            "booking-page"
        );

        return sendPreConversionEvent(payload);
    }

    // booking page -> conversion event
    const booking = document.getElementById("booking_page");
    if (booking) {
        const payload = buildConversionData(
            booking.getAttribute("data-adult-number"),
            booking.getAttribute("data-cabin-class"),
            booking.getAttribute("data-child-number"),
            booking.getAttribute("data-conversion-id"),
            currency,
            booking.getAttribute("data-fare-basis-codes"),
            booking.getAttribute("data-flight-fare"),
            booking.getAttribute("data-infant-number"),
            booking.getAttribute("data-legs"),
            booking.getAttribute("data-marketing-carriers"),
            booking.getAttribute("data-payment-method"),
            booking.getAttribute("data-route-type"),
            booking.getAttribute("data-transaction-total")
        );

        return sendConversionEvent(payload);
    }

    // itinerary listing (step1) -> pre conversion event
    const step1 = document.getElementById("step_1");
    if (step1) {
        let payload;

        const itineraryMobile = step1.querySelector('.itinerary-mobile');
        if (itineraryMobile){
            const priceElement = itineraryMobile.querySelector('.total-price .price');

            payload = buildPreConversionData(
                currency,
                itineraryMobile.getAttribute('data-fare-basis-codes'),
                itineraryMobile.getAttribute('data-marketing-carriers'),
                priceElement ? priceElement.innerText : '0',
                'dayview-page'
            );
        } else {
            payload = buildPreConversionData(
                currency,
                '',
                '',
                '0',
                'dayview-page'
            );
        }

        return sendPreConversionEvent(payload);
    }

    // homepage(index) -> pre conversion event
    const homepage = document.getElementById("index");
    if (homepage) {
        const payload = buildPreConversionData(
            currency,
            '',
            '',
            '0',
            'home-page'
        );

        return sendPreConversionEvent(payload);
    }
});
